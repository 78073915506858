import { Button } from "src/shadcn/ui/button";
import { LinkedInIcon } from "src/assets/images";

const LinkedInLoginButton = () => {
  // functions
  const handleLogin = () => {
    // **staging**
    window.location.href =
      "https://test.auth.acquiretalent.co/oauth2/authorize?identity_provider=LinkedIn&redirect_uri=https://test.platform.acquiretalent.co/auth-callback&response_type=CODE&client_id=5h00tgv485765k9ht2ueuend1f&scope=email openid profile";

    // **production**
    // window.location.href =
    // "https://prod.auth.acquiretalent.co/oauth2/authorize?identity_provider=LinkedIn&redirect_uri=https://platform.acquiretalent.co/auth-callback&response_type=CODE&client_id=6bccv1gs20u80fquaebtviltcv&scope=aws.cognito.signin.user.admin email openid profile";
  };

  return (
    <Button
      type="button"
      variant="outline"
      className="w-full h-10 flex items-center justify-between border border-[#e2e8f0] px-3"
      onClick={() => handleLogin()}
    >
      <img
        src={LinkedInIcon}
        alt="LinkedIn"
        width={20}
        height={20}
        className="mr-3"
        loading="lazy"
      />
      <span className="text-sm font-medium">Continue with LinkedIn</span>
      <div></div>
    </Button>
  );
};

export default LinkedInLoginButton;
